import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../SatCoreRegistry';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../utils/session';

import {
  INSTITUTION_DROPDOWN_MODE,
  ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ
} from '../services/UserInstitutionDropdownConstants';

import Auth from './AuthManager';

export const USER_ENDPOINTS = {
  ACTIVATE_USER_INVITATION: '/api/activateUserInvitation',
  CREATE_USER_INSTITUTION: '/api/addUserInstitution',
  GET_USER_INVITATION: '/api/getUserInvitation',
  GET_USE_ASSIGNMENT_REACT_PLAYER: '/api/validateReactForActivity',
  REGISTER_DEMOLINK_USER: '/api/generateDemoLinkUser',
  REGISTER_ECOMMERCE_USER: '/api/generateEcommerceUser',
  REMOVE_USER_INSTITUTION: '/api/removeUserInstitution',
  SAVE_USER_DEFAULT_AVATAR: '/api/saveUserDefaultAvatar',
  VIEW_SINGULAR_INSTITUTION: '/api/viewInstitutionForSatellite',
  VIEW_USER_INSTITUTIONS: '/api/viewUserInstitutionsForSatellite',
};

export const ERROR_MESSAGES = {
  ACCESS_CODE_INVALID: 'This access code does not work. Please check your code and try again.',
  GENERAL: 'Something has gone wrong with your registration.  ' +
    'Please check your information and try again.  If you think everything is correct, please contact your administrator.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match!',
  PASSWORD_LENGTH: 'Password must be at least 8 characters and cannot be all spaces.',
  STUDENT_USERNAME_INVALID_EMAIL: 'Student username cannot be an email address.',
  USERNAME_EXISTS: 'There is already a user with the Username/email you entered.  Try again using a different username.',
  USER_EXISTS: 'There is already a user in the system with that username/email. Please try another username/email.',
  USER_EXISTS_NO_EMAIL: 'There is already a user in the system with that username. Please try another username.',
  VALID_EMAIL_FORMAT: 'Please enter a valid email format. (name@example.com)',
};

export class UserManager {
  districtAdminId = 'district_admin_user';
  districtProductAdminId = 'district_product_admin';
  experimentalAccessId = 'experimental_access';
  manageLearnosityScoresId = 'manage_learnosity_scores';
  manageUploadedFilesId = 'manage_uploaded_files';
  publisherAdminId = 'manage_publisher_products';
  publisherUserId = 'publisher_user';
  reactPlayerPermissionId = 'view_react_player';
  satelliteAdminId = 'satellite_admin_user';
  satelliteBetaAccessUserId = 'satellite_beta_access';
  schoolAdminId = 'school_admin_user';
  schoolProductAdminId = 'school_product_admin';
  studentPermissionId = 'student_user';
  teacherPermissionId = 'teacher_user';
  viewEngagementDataId = 'view_engagement_data';
  viewSatelliteContent = 'view_satellite_content';
  viewLibraryItemBank = 'view_library_item_bank';
  viewLibraryResourceBank = 'view_library_resource_bank'

  @observable allowSwitchRole = true; // set via `setAllowSwitchRole(true|false)` flag in satellite index.js

  // TODO remove // @observable accommodationIds = {};
  @observable activePermissionId = '';
  @observable permissionIds = [];

  @observable authKey = '';
  @observable displayName = '';
  @observable firstName = '';
  @observable fullProfileImageUrl = '';
  @observable institutionImage = null;
  @observable institutionState = '';
  @observable institutionStateId = '';
  @observable isSsoUser = false;
  @observable lastName = '';
  @observable loaded = false;
  @observable profileImageUrl = '';
  @observable user = null;
  @observable userId = '';
  @observable username = '';
  @observable pingErrorCount = 0;

  @observable hideClassMenuDropdown = false;

  /** **primary** `institutionId` but a user may have multiple `institutionIds` */
  @observable institutionId = null;
  @observable selectedDropdownInstitutionIdObservableObj = {
    addClassroomMode: getSessionStorageItem('selectedDropdownInstitutionIdStr') || 'allInstitutions',
    selectClassroomMode: getSessionStorageItem('selectedDropdownInstitutionIdStr') || 'allInstitutions',
    selectReportClassroomMode: getSessionStorageItem('selectedDropdownInstitutionIdStr') || 'allInstitutions'
  };

  /** allows the ability to provide computed arrays: `institutionIds` and `institutions` */
  @observable institutionsMap = new Map();

  @observable restrictAvatar = false;
  @observable accessCodeResult = false;
  @observable emailResult = false;
  @observable institutionName = '';
  @observable institutionTimezoneid = '';
  @observable clientPrefix = '';
  @observable isCoTeacher = false;
  @observable enabled = false;
  @observable timezoneId = '';
  @observable creationDate = '';
  @observable lastLogin = '';
  @observable loginAttempts = '';
  @observable textHelpEnabled = false;
  @observable publisherTextHelpActive = false;
  @observable showTextHelpForAll = false;
  @observable publisherId = '';
  @observable isImpersonatorAdmin = false;
  @observable allowStudentUsernamesAsEmail = true;
  @observable allowViewSatelliteProductTab = true;
  @observable allowTeacherLibraryContentView = true;
  @observable allowTeacherTestBuilderView = false;
  @observable allowAdminTestBuilderView = true;

  courseTeacher = false;
  courseStudent = false;

  constructor() {
    makeObservable(this);
  }

  @action clear() {
    this.accessCodeResult = false;
    this.authKey = '';
    this.clearActivePermissionId();
    this.creationDate = '';
    this.displayName = '';
    this.emailResult = false;
    this.enabled = false;
    this.firstName = '';
    this.fullProfileImageUrl = '';
    this.hideClassMenuDropdown = false;
    this.institutionId = null;
    this.institutionImage = null;
    this.institutionName = '';
    this.institutionState = '';
    this.institutionStateId = '';
    this.institutionTimezoneid = '';
    this.institutionsMap = new Map();
    this.isCoTeacher = false;
    this.isImpersonatorAdmin = false;
    this.lastLogin = '';
    this.lastName = '';
    this.loaded = false;
    this.loginAttempts = '';
    this.permissionIds = [];
    this.profileImageUrl = '';
    this.publisherId = '';
    this.selectedDropdownInstitutionIdObservableObj = { ...ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ };
    this.timezoneId = '';
    this.userId = '';
    this.username = '';
  }

  @action clearSession() {
    removeSessionStorageItem('selectedDropdownInstitutionIdStr');
  }

  @action setIsImpersonatorAdmin(toggle) {
    this.isImpersonatorAdmin = toggle;
  }

  @action getIsImpersonatorAdmin() {
    return this.isImpersonatorAdmin;
  }

  @action setLoaded(toggle) {
    this.loaded = toggle;
  }

  @action setAllowSwitchRole = (allowSwitchRole) => {
    this.allowSwitchRole = allowSwitchRole;
  }

  @action setActivePermissionId = (permissionId) => {
    this.activePermissionId = permissionId;
    if (permissionId) {
      setSessionStorageItem('activePermissionId', permissionId);
    }
  }

  @action clearActivePermissionId = () => {
    this.activePermissionId = null;
    removeSessionStorageItem('activePermissionId');
  }

  @action setIsCoTeacher(toggle) {
    this.isCoTeacher = toggle;
  }

  @action setHideClassMenuDropdown(hide) {
    this.hideClassMenuDropdown = hide;
  }

  @action setRestrictAvatar(restrict) {
    this.restrictAvatar = restrict;
  }

  @action setClientPrefix(prefix) {
    this.clientPrefix = prefix;
  }

  @action setAccessCodeResult(result) {
    this.accessCodeResult = result;
  }

  @action setEmailResult(result) {
    this.emailResult = result;
  }

  @action setPublisherTextHelpActive(active) {
    this.publisherTextHelpActive = active;
  }

  @action setShowTextHelpForAll(show) {
    this.showTextHelpForAll = show;
  }

  @action setTextHelpEnabled(enabled) {
    this.textHelpEnabled = enabled;
  }

  setCourseTeacher = (toggle) => {
    this.courseTeacher = toggle;
  }

  setCourseStudent = (toggle) => {
    this.courseStudent = toggle;
  }

  @action setUser = (user, institutions) => {
    this.user = user;

    const now = new Date();
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.username = user.username;
    this.displayName = user.displayName;

    // TODO remove // this.addUserInstitutionsToMap(institutions);

    this.permissionIds = user.permissionIds;

    let activePermissionId;
    if (this.hasPublisherPermission) {
      this.setActivePermissionId(this.publisherUserId);
    } else {
      const UserService = getRegisteredClass('UserService');
      const availableRoles = UserService.getAvailableRoles();

      // if only one role, we can assume that is the current role of the user
      // otherwise, we will set active role to sessionStorage activePermissionId if it exists
      // if activePermissionId is null, MainView will redirect the user to RoleSwitcherView (to choose an available role)
      if (!this.allowSwitchRole || availableRoles.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        activePermissionId = availableRoles[0];
        this.setActivePermissionId(activePermissionId);
      } else {
        activePermissionId = getSessionStorageItem('activePermissionId');
        this.setActivePermissionId(activePermissionId);
      }
    }

    // TODO remove // this.accommodationIds = user.accommodationIds;
    this.profileImageUrl = user.profileImageUrl;
    this.fullProfileImageUrl = `${user.fullProfileImageUrl}&authKey=${user.authKey}&rn=${now.getTime()}`;
    this.userId = user.userId;
    this.authKey = user.authKey;
    Auth.setAuthKey(this.authKey);
    this.loaded = true;

    this.isSsoUser = user.isSsoUser && user.isSsoUser !== 'false';

    if (institutions) {
      this.addUserInstitutionsToMap(institutions);
    }

    // set 'primary' institutionId
    // if district admin, FE will treat 'primary' institutionId as the first districtId it finds
    this.institutionId = activePermissionId === this.districtAdminId && institutions ?
      institutions.find((institution) => {
        return institution?.district || institution?.type?.toLowerCase() === 'district';
      })?.id || user.institutionId : user.institutionId;

    this.institutionState = user.institutionState;
    this.institutionStateId = user.institutionStateId;
    this.institutionName = user.institutionName;
    this.institutionTimezoneid = user.institutionTimezoneid;

    this.enabled = user.enabled;
    this.timezoneId = user.timezoneId;
    this.creationDate = user.creationDate;
    this.lastLogin = user.lastLogin;
    this.loginAttempts = user.loginAttempts;
    this.publisherId = user.publisherId;
  }

  createFullAuthUrl = (url) => {
    const now = new Date();
    return `${url}&authKey=${this.authKey}&rn=${now.getTime()}`;
  }

  @action setUserEnabled(enabled) {
    this.enabled = enabled;
  }

  @action setFirstName(name) {
    this.firstName = name;
  }

  @action setLastName(name) {
    this.lastName = name;
  }

  @action setAllowViewSatelliteProductTab(allow) {
    this.allowViewSatelliteProductTab = allow;
  }

  @action setAllowTeacherLibraryContentView(allow) {
    this.allowTeacherLibraryContentView = allow;
  }

  @action setAllowTeacherTestBuilderView(allow) {
    this.allowTeacherTestBuilderView = allow;
  }

  @action setAllowAdminTestBuilderView(allow) {
    this.allowAdminTestBuilderView = allow;
  }

  @action async getTempStudentCode() {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/generateRandomCode`, {
        method: 'POST'
      });

      if (response.tempRandomCode) {
        return response.tempRandomCode;
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action setAllowStudentUsernamesAsEmail(allowStudentUsernamesAsEmail) {
    this.allowStudentUsernamesAsEmail = allowStudentUsernamesAsEmail;
  }

  async fetchUser(userId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getUser?userId=${userId}`, {
        method: 'GET'
      });

      if (response.status === 'SUCCESS') {
        if (response.users && response.users.length > 0) {
          return response.users[0];
        }
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }

  async fetchMyUserOptions() {
    // if we know, don't do it again.
    // if (this.publisherTextHelpActive !== null) {
    //  return;
    // }
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/getMyUserOptions`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setPublisherTextHelpActive(response.textHelpActive);
        this.setTextHelpEnabled(response.textHelpEnabled);
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }

  @action fetchUseAssignmentReactPlayer = async (activityId) => {
    try {
      let apiUrl = `${Auth.ecms}${USER_ENDPOINTS.GET_USE_ASSIGNMENT_REACT_PLAYER}`;
      apiUrl += `?id=${activityId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response?.status === 'SUCCESS' && response?.isReact) {
        return response?.isReact;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  @action checkUser = async () => {
    const user = await Auth.checkUser();
    if (user) {
      const primaryInstitution = await this.fetchSingularUserInstitution(user.institutionId);
      const institutions = await this.fetchUserInstitutions(user);
      this.institutionsMap.clear();
      this.addUserInstitutionsToMap([...institutions, primaryInstitution]);
      this.setUser(user, institutions);
      return true;
    }
    return false;
  }

  @action impersonatorLogIn = async (authKey) => {
    const result = await this.authKeyLogIn(authKey);
    if (result) {
      this.setIsImpersonatorAdmin(true);
      return true;
    }
    return false;
  }

  @action cleverLogIn = async (authKey) => this.authKeyLogIn(authKey)

  @action authKeyLogIn = async (authKey) => {
    try {
      const user = await Auth.logInWithAuthKey(authKey);
      if (user && user.status === 'SUCCESS') {
        this.setUser(user);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action publisherLogIn = async (authKey) => {
    try {
      const user = await Auth.logInWithAuthKey(authKey);
      if (user && user.status === 'SUCCESS') {
        this.setUser(user);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action createUserInstitution = async ({
    institutionId,
    userId
  }) => {
    try {
      const apiUrl = `${Auth.ecms}${USER_ENDPOINTS.CREATE_USER_INSTITUTION}`;
      const response = await Auth.fetch(apiUrl, {
        body: { institutionId, userId },
        method: 'POST'
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchSingularUserInstitution = async (institutionId) => {
    try {
      let apiUrl = `${Auth.ecms}${USER_ENDPOINTS.VIEW_SINGULAR_INSTITUTION}`;
      apiUrl += `?institutionId=${institutionId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response?.status === 'SUCCESS' && response.institutions) {
        return response.institutions[0];
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchUserInstitutions = async (user, {
    userId = null,
    pageSize = 999,
    skip = 0
  } = {}) => {
    try {
      userId = userId || user?.userId || user?.id || this.userId;
      let apiUrl = `${Auth.ecms}${USER_ENDPOINTS.VIEW_USER_INSTITUTIONS}`;
      apiUrl += `?userId=${userId}`;
      apiUrl += `&pageSize=${pageSize}`;
      apiUrl += `&skip=${skip}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response?.status === 'SUCCESS') {
        return response.data || [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getUserInvitation = async (userId) => {
    try {
      let apiUrl = `${Auth.ecms}${USER_ENDPOINTS.GET_USER_INVITATION}`;
      apiUrl += `?id=${userId}`;
      const response = await Auth.fetch(apiUrl, { method: 'GET' });
      if (response?.status === 'SUCCESS') {
        return response;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  activateUserInvitation = async (userId, firstName, lastName, password, authKey) => {
    try {
      const body = {
        id: userId,
        firstName,
        lastName,
        password,
        authKey
      };
      const apiUrl = `${Auth.ecms}${USER_ENDPOINTS.ACTIVATE_USER_INVITATION}`;

      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });

      if (response?.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @param {string} institutionId
   * @param {import('../services/UserInstitutionDropdownConstants').InstitutionDropDownMode} mode
   */
  @action setSelectedDropdownInstitutionId = (institutionId, mode = INSTITUTION_DROPDOWN_MODE.ALL_MODES) => {
    const { ADD_CLASSROOM_MODE, ALL_MODES, SELECT_CLASSROOM_MODE, SELECT_REPORT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
    if (mode === ALL_MODES) {
      this.selectedDropdownInstitutionIdObservableObj[ADD_CLASSROOM_MODE] = institutionId;
      this.selectedDropdownInstitutionIdObservableObj[SELECT_CLASSROOM_MODE] = institutionId;
      this.selectedDropdownInstitutionIdObservableObj[SELECT_REPORT_CLASSROOM_MODE] = institutionId;
    } else {
      this.selectedDropdownInstitutionIdObservableObj[mode] = institutionId;
    }
    setSessionStorageItem('selectedDropdownInstitutionIdStr', institutionId);
  }

  @action addUserInstitutionsToMap = (institutions = []) => {
    for (const institution of institutions) {
      this.institutionsMap.set(institution.id, institution);
    }
  }

  @action setInstitutionImage = (image) => {
    this.institutionImage = image;
  }

  fetchInstitutionImage = async () => {
    if (this.userId !== null && this.userId !== undefined && this.userId !== '') {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/viewMyInstitution`, {
          method: 'GET'
        });
        if (response && response.status && response.status === 'SUCCESS') {
          const institution = response.data[0];
          if (institution !== null && institution !== undefined) {
            this.setInstitutionImage(institution.imageContentItemId);
          }
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    }
    return null;
  }

  @action uploadImage = async (formData) => {
    const token = Auth.getToken();

    const headers = new Headers({
      Authorization: token,
      Accept: 'application/json; text/plain'
    });

    try {
      const response = await fetch(`${Auth.ecms}/api/saveUserProfileImage?saveUserProfile=false`, {
        method: 'POST',
        body: formData,
        headers

      });
      const data = await response.json();
      return data.newFileImageUrl;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  verifyUser = async (verificationId) => {
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/verifyUser?id=${verificationId}`, {
        method: 'GET'
      });

      if (result && result.status && result.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action async setProfileImageUrl(newFileName, save, userId = null) {
    const now = new Date();
    this.profileImageUrl = newFileName;
    const tempArray = this.fullProfileImageUrl.split('resourceFileName=');
    if (!tempArray || !tempArray[0]) {
      this.fullProfileImageUrl = newFileName;
    } else {
      this.fullProfileImageUrl = `${tempArray[0]}resourceFileName=${newFileName}&authKey=${this.authKey}&rn=${now.getTime()}`;
    }
    if (save) {
      await this.saveImage(newFileName, userId);
    }
  }

  getFullUrlFromName = (imageName) => {
    const now = new Date();
    if (imageName === null || this.fullProfileImageUrl === null) {
      return null;
    }
    const tempArray = this.fullProfileImageUrl.split('resourceFileName=');
    if (!tempArray || !tempArray[0]) {
      return imageName;
    }
    return `${tempArray[0]}resourceFileName=${imageName}&authKey=${this.authKey}&rn=${now.getTime()}`;
  }

  @action saveUserDefaultAvatar = async (selectedAvatar, userId = null) => {
    try {
      const body = {
        profileImage: `85px-${selectedAvatar}.png`,
        userType: this.isStudent ? 'student' : 'teacher',
        ...(userId ? { userId } : {})
      };
      const apiUrl = `${Auth.ecms}${USER_ENDPOINTS.SAVE_USER_DEFAULT_AVATAR}`;

      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });

      if (response && response.status === 'SUCCESS') {
        const save = false;
        await this.setProfileImageUrl(response.imageName, save, userId);
        return response.imageName;
      }
    } catch (error) {
      console.error(error);
    }
  }

  submitCrop = async (newFileName, crop) => {
    const body = {
      x: String(crop.x),
      y: String(crop.y),
      toX: String(crop.x), // not used in backend
      toY: String(crop.y), // not used in backend
      width: String(crop.width),
      height: String(crop.height),
      fileName: String((newFileName) || this.profileImageUrl)
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/cropImagesForImagePicker?imageFromResources=false&folderName=avatars`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return response.newFileName;
      }
      return null;
    } catch (e) {
      console.error(e);
    }
  }

  getFullProfileImageUrl = (imageUrl) => {
    const now = new Date();
    if (imageUrl !== null && imageUrl !== undefined && imageUrl != '') {
      return `${Auth.ecms}/api/redirectToResource?resourceFolder=avatars&resourceFileName=${imageUrl}&authKey=${Auth.authKey}&rn=${now.getTime()}`;
    }
    return null;
  }

  @action updatePassword = async (oldPassword, newConfirmPassword) => {
    const body = {
      oldPassword,
      newPassword: newConfirmPassword
    };

    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/updatePassword`, {
        method: 'POST',
        body
      });
      if (result && result.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  @action saveChanges = async (input) => {
    try {
      const body = {
        userId: input.userId,
        firstName: input.firstName,
        lastName: input.lastName
      };

      if (input.enabled != undefined) {
        body.enabled = input.enabled;
      }

      if (input.timezoneId) {
        body.timezoneId = input.timezoneId;
      }

      if (input.username) {
        body.username = input.username;
      }

      if (input.id) {
        body.id = input.id;
      }

      if (input.password) {
        body.password = input.password;
      }

      const data = await Auth.fetch(`${Auth.ecms}/api/updateUserProfile`, {
        method: 'POST',
        body
      });

      if (data && data.status === 'SUCCESS') {
        this.setFirstName(body.firstName);
        this.setLastName(body.lastName);
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  @action enableUser = async (userId) => {
    try {
      const body = {
        userId
      };

      const data = await Auth.fetch(`${Auth.ecms}/api/activateUser`, {
        method: 'POST',
        body
      });

      if (data && data.status === 'SUCCESS') {
        this.setUserEnabled(true);
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  saveImage = async (imageName, userId = null) => {
    try {
      const body = {
        profileImage: imageName
      };
      if (userId) {
        body.userId = userId;
      }
      const data = await Auth.fetch(`${Auth.ecms}/api/updateUserProfile`, {
        method: 'POST',
        body
      });
      if (data && data.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  @action resetPassword = async (passwordChangeId, password) => {
    let response = null;
    try {
      response = await Auth.fetch(`${Auth.ecms}/api/changePassword?passwordChangeId=${passwordChangeId}&password=${password}`, {
        method: 'POST'
      });
    } catch (e) {
      console.error(e);
      return false;
    }

    if (response && response.status === 'SUCCESS') {
      return true;
    }
  }

  @action sendResetEmail = async (email) => {
    let response = null;
    try {
      response = await Auth.fetch(`${Auth.ecms}/api/sendResetPasswordEmail?publisherSatelliteCode=${Auth.publisherSatelliteCode}&emailAddress=${email}`, {
        method: 'POST'
      });
    } catch (e) {
      console.error(e);
      return false;
    }

    if (response && response.status === 'SUCCESS') {
      if (response.emailSent === false) {
        return false;
      }
      return true;
    }
    return false;
  }

  validateAccessCode = async (accessCode) => {
    try {
      const data = await Auth.fetch(`${Auth.ecms}/api/validateSignUpCode`, {
        method: 'POST',
        body: {
          signupCode: accessCode.value,
          publisherSatelliteCode: Auth.publisherSatelliteCode
        }
      });
      this.setAccessCodeResult(data);
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  validateUser = async (accessCode, email) => {
    const { result } = accessCode;

    try {
      const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
        method: 'POST',
        body: { publisherId: result.publisherId, emailAddress: email.value }
      });

      this.setEmailResult(data);

      if (data.emailAddressExists) {
        email.error = ERROR_MESSAGES.USER_EXISTS;
      } else {
        email.error = null;
      }

      if (email.error !== null) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  validateUserEmail = async (publisherId, emailAddress) => {
    try {
      const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
        method: 'POST',
        body: { publisherId, emailAddress }
      });

      if (data.emailAddressExists) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  // Validates the student username.  If it's an email format, checks if satellite allows student email.
  validateStudentUsernameFormat = (username) => {
    let errorMessage = null;
    // First check if username has a value
    let valid = username && username.length > 0;
    if (valid) {
      // if so, check if username is email
      const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmail = emailRegexp.test(username);
      if (isEmail && !this.allowStudentUsernamesAsEmail) {
        valid = false;
        errorMessage = ERROR_MESSAGES.STUDENT_USERNAME_INVALID_EMAIL;
      }
    } else {
      errorMessage = ERROR_MESSAGES.VALID_EMAIL_FORMAT;
    }
    const usernameResult = {
      valid,
      errorMessage
    };
    return usernameResult;
  }

  updateStudentTextHelpOption = async (userId, textHelpEnabled) => {
    const body = {
      userId,
      textHelpEnabled
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/updateStudentTextHelpOption`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateMyTextHelpOption = async (textHelpEnabled) => {
    const body = {
      textHelpEnabled
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/updateMyTextHelpOption`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  registerUser = async (body, accessCode) => {
    let url = Auth.ecms;
    // determine which signup url to call
    if (accessCode) {
      if (accessCode.codeType === 'district' || accessCode.codeType === 'school') {
        switch (accessCode.codeCategory) {
          case 'district_admin':
            url += '/api/signupDistrictAdmin';
            break;
          case 'school_admin':
            url += '/api/signupSchoolAdmin';
            break;
          case 'user':
            url += '/api/signupTeacher';
            break;
          default:
        }
      } else if (accessCode.codeType === 'classroom') {
        switch (accessCode.codeCategory) {
          case 'classroom':
            url += '/api/signupStudent';
            break;
          case 'coteacher':
            url += '/api/signupTeacher';
            break;
          default:
        }
      }
    } else if (!accessCode && body.userType === 'teacher') {
      // special case for admin adding a teacher user
      url += '/api/signupTeacher';
    }

    try {
      const data = await Auth.fetch(url, {
        method: 'POST',
        body
      });
      // if the status is SUCCESS and there is an ID then new user registered
      if (data.status === 'SUCCESS' && data.userId) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  registerDemolinkUser = async ({ demolinkId } = {}) => {
    try {
      const apiUrl = `${Auth.ecms}${USER_ENDPOINTS.REGISTER_DEMOLINK_USER}`;
      const result = await Auth.fetch(apiUrl, {
        body: {
          code: demolinkId,
          publisherSatelliteCode: Auth.publisherSatelliteCode
        },
        method: 'POST'
      });
      if (result.status !== 'SUCCESS') {
        console.error(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  registerEcommerceUser = async (body) => {
    try {
      const apiUrl = `${Auth.ecms}${USER_ENDPOINTS.REGISTER_ECOMMERCE_USER}`;
      const result = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  trackingInterval = null;

  async trackLoggedIn() {
    // avoid rogue interval if a new login happens before unauthorized
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
    }

    this.trackingInterval = setInterval(async () => {
      this.pingLoggedIn();
    }, 60000);
  }

  pingLoggedIn = async () => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/ping`, {
        method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        // console.log('ALIVE');
        this.pingErrorCount = 0;
        return true;
      }
      return false;
    } catch (error) {
		console.log(error);
      if (error.message === 'Unauthorized' || error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
        this.pingErrorCount++;
        if (this.pingErrorCount > 3) {
          clearInterval(this.trackingInterval);
			Auth.logout();
			window.location = '/signin';
			return false;
		}
      }
      return true;
    }
  }

  // --------------------------------------------------------------------
  // ROLES (`activePermissionId` determines the current satellite 'role')
  // --------------------------------------------------------------------

  @computed get isDistrictOrSchoolAdmin() {
    return this.activePermissionId === this.districtAdminId || this.activePermissionId === this.schoolAdminId;
  }

  @computed get isDistrictAdmin() {
    return this.activePermissionId === this.districtAdminId;
  }

  @computed get isSchoolAdmin() {
    return this.activePermissionId === this.schoolAdminId;
  }

  @computed get isDistrictProductAdmin() {
    return this.activePermissionId === this.districtProductAdminId;
  }

  @computed get isSchoolProductAdmin() {
    return this.activePermissionId === this.schoolProductAdminId;
  }

  @computed get isProductAdmin() {
    return this.isDistrictProductAdmin || this.isSchoolProductAdmin;
  }

  @computed get isTeacher() {
    return this.activePermissionId === this.teacherPermissionId || this.isSatAdmin;
  }

  @computed get isStudent() {
    return this.activePermissionId === this.studentPermissionId;
  }

  @computed get isLicensedBySite() {
    const UserService = getRegisteredClass('UserService');
    const selectedInstitution = UserService.getCurrentInstitutionForLoggedInUser({
      useDefaultInstitutionIfNoneSelected: false
    });
    if (this.isTeacher && selectedInstitution) {
      return selectedInstitution?.licenseModel === 'SITE_LICENSE';
    } else {
      return !!this.institutions?.find((institution) => {
        return institution?.licenseModel === 'SITE_LICENSE';
      });
    }
  }

  @computed get isLicensedByPurchaseCode() {
    const UserService = getRegisteredClass('UserService');
    const selectedInstitution = UserService.getCurrentInstitutionForLoggedInUser({
      useDefaultInstitutionIfNoneSelected: false
    });
    if (this.isTeacher && selectedInstitution) {
      return selectedInstitution?.licenseModel === 'PURCHASE_CODE';
    } else {
      return !!this.institutions?.find((institution) => {
        return institution?.licenseModel === 'PURCHASE_CODE';
      });
    }
  }

  @computed get licenseInstruction() {
    return this.institutions?.find((institution) => {
      return institution?.licenseInstruction;
    })?.licenseInstruction;
  }

  @computed get licenseInstructionUrl() {
    return this.institutions?.find((institution) => {
      return institution?.licenseInstructionUrl;
    })?.licenseInstructionUrl;
  }

  // --------------------------------------------------------------------
  // PERMISSIONS
  // --------------------------------------------------------------------

  @computed get hasPublisherPermission() {
    return this.loaded && (
      this.permissionIds.includes(this.publisherAdminId) ||
      this.permissionIds.includes(this.publisherUserId)
    );
  }

  @computed get hasDistrictAdminPermission() {
    return this.loaded && this.permissionIds.includes(this.districtAdminId);
  }

  @computed get hasSchoolAdminPermission() {
    return this.loaded && this.permissionIds.includes(this.schoolAdminId);
  }

  @computed get hasProductAdminPermission() {
    return this.hasDistrictProductAdminPermission || this.hasSchoolProductAdminPermission;
  }

  @computed get hasDistrictProductAdminPermission() {
    return this.loaded && this.permissionIds.includes(this.districtProductAdminId);
  }

  @computed get hasSchoolProductAdminPermission() {
    return this.loaded && this.permissionIds.includes(this.schoolProductAdminId);
  }

  @computed get hasTeacherPermission() {
    return this.loaded && this.permissionIds.includes(this.teacherPermissionId);
  }

  @computed get hasStudentPermission() {
    return this.loaded && this.permissionIds.includes(this.studentPermissionId);
  }

  @computed get hasSatelliteBetaAccessUserPermission() {
    return this.loaded && this.permissionIds.includes(this.satelliteBetaAccessUserId);
  }

  @computed get hasExperimentalAccessPermission() {
    return this.loaded && this.permissionIds.includes(this.experimentalAccessId);
  }

  @computed get hasManageUploadedFilesPermission() {
    return this.loaded && this.permissionIds.includes(this.manageUploadedFilesId);
  }

  @computed get hasViewEngagementDataPermission() {
    return this.loaded && this.permissionIds.includes(this.viewEngagementDataId);
  }

  /** edge case, currently only used by EMP & EUC */
  @computed get isSatAdmin() {
    return this.loaded && this.permissionIds.includes(this.satelliteAdminId);
  }

  @computed get canViewAsTeacher() {
    return (this.loaded && (this.permissionIds.includes(this.teacherPermissionId) ||
      this.permissionIds.includes(this.satelliteAdminId) ||
      this.permissionIds.includes(this.districtAdminId) ||
      this.permissionIds.includes(this.schoolAdminId) ||
      this.permissionIds.includes(this.publisherAdminId) ||
      this.permissionIds.includes(this.publisherUserId) ||
      this.permissionIds.includes(this.districtProductAdminId) ||
      this.permissionIds.includes(this.schoolProductAdminId))
    );
  }

  @computed get hasMultipleInstitutions() {
    return this.institutionsMap.size > 1;
  }

  @computed get institutionDropdownOptions() {
    const keys = this.institutionIds;
    const options = keys.map((institutionId) => {
      const institution = this.institutionsMap.get(institutionId);
      return {
        text: institution.name,
        value: institution.id,
        type: institution.type
      };
    }).sort((a, b) => a.text?.localeCompare(b.text, 'en', { numeric: true }));
    return options;
  }

  @computed get institutionIds() {
    return Array.from(this.institutionsMap.keys()).sort((a, b) => {
      return a.name?.localeCompare(b.name, 'en', { numeric: true });
    });
  }

  @computed get institutions() {
    return Array.from(this.institutionsMap.values()).sort((a, b) => {
      return a.name?.localeCompare(b.name, 'en', { numeric: true });
    });
  }

  @computed get canManageLearnosityScores() {
    return (this.loaded && this.permissionIds.includes(this.manageLearnosityScoresId));
  }

  @computed get canViewSatelliteTestBuilder() {
    const hasTestBuilderPermission = (
      this.permissionIds.includes(this.viewLibraryItemBank) || this.permissionIds.includes(this.viewLibraryResourceBank)
    );
    if (this.isDistrictOrSchoolAdmin) {
      return this.loaded && this.allowAdminTestBuilderView && hasTestBuilderPermission;
    } else if (this.isTeacher) {
      return this.loaded && this.allowTeacherTestBuilderView && hasTestBuilderPermission;
    } else {
      return false;
    }
  }

  @computed get canViewSatelliteContent() {
    return (this.loaded && this.permissionIds.includes(this.viewSatelliteContent));
  }

  @computed get canViewSatelliteProducts() {
    return (this.loaded && this.allowViewSatelliteProductTab);
  }

  @computed get canViewLibraryItemBank() {
    return (this.loaded && this.permissionIds.includes(this.viewLibraryItemBank));
  }

  @computed get canViewLibraryResourceBank() {
    return (this.loaded && this.permissionIds.includes(this.viewLibraryResourceBank));
  }
}

export default new UserManager();
