/* eslint-disable max-len */
const translations = {
  AddCourseToClassModal: {
    selectCourseCardTopLabel: 'Select'
  },
  AdminAddTeacher: {
    username: 'Email'
  },
  AdminUserProfile: {
    username: 'Teacher Email/Student Username'
  },
  AdminTestBuilderView: {
    myDraftsDescriptionTeacher: 'Build a standards-based assignment for individuals or small groups. Before using Skill Builder™ for the first time, click here for <a href="https://starhelp.simplesolutions.org/portal/en/kb/articles/getting-started-with-mastery-builder-studio" target="_blank">instructions.</a>',
  },
  AdminTopNav: {
    testBuilderLabel: 'Skill Builder™',
    testBuilderRollover: 'Currently, the Skill Builder™ is available for Standards-Based Math (Levels K-8), Standards-Based English (Levels 2-8), and Math (Levels 2-8).'
  },
  AssignmentManager: {
    EXPORT_AGGREGATE_INSTANCES_MINI: 'Summary Export',
    EXPORT_AGGREGATE_MINI_ANALYSIS: 'Item Analysis Export',
  },
  AssignmentMenu: {
    CurrentAssignments: 'Current'
  },
  AssignmentView: {
    assignedHoverText: 'Assignments will remain in this tab until all students have started.',
    gradedHoverText: 'This activity has been completed by at least one student.',
    inProgressHoverText: 'This activity has been started by at least one student.',
    needsGradingHoverText: 'This tab represents assignments that were closed without being submitted. Assignments can be edited in the gradebook to move them to the Graded tab.'
  },
  CreateNewLibraryResourceModal: {
    customNameLabelSubText: 'This name will appear at the top of the assignment.',
    templateLabelSubText: '',
    templateLabelText: 'Template',
  },
  CourseCard: {
    readOnlyPreview: 'Preview'
  },
  EditStudentProfile: {
    username: 'Teacher Email/Student Username'
  },
  GradebookTable: {
    grade: 'Percent',
  },
  GradebookDetailsTable: {
    gradeHeaderLabel: 'Percent',
  },
  Login: {
    buttonSignUpLabel: 'Sign Up',
    username: 'Teacher Email/Student Username'
  },
  Profile: {
    username: 'Teacher Email/Student Username'
  },
  Register: {
    userExistsError: 'There is already a user with this username assigned in the S²TaR center.  Please try another username.',
    username: 'Teacher Email/Student Username',
  },
  RegisterStudentModal: {
    emailLabel: 'Student Username',
    userExistsErrorNoEmail: 'There is already a user with this username assigned in the S²TaR center.  Please try another username.'
  },
  TeacherDashboard: {
    WelcomeLabel: 'Welcome,'
  },
  TeacherTestBuilderView: {
    myDraftsDescriptionTeacher: 'Build a standards-based assignment for individuals or small groups. Before using Skill Builder™ for the first time, click here for <a href="https://starhelp.simplesolutions.org/portal/en/kb/articles/getting-started-with-mastery-builder-studio" target="_blank">instructions.</a>',
  },
  TopNav: {
    testBuilderLabel: 'Skill Builder™',
    testBuilderRollover: 'Currently, the Skill Builder™ is available for Standards-Based Math (Levels K-8), Standards-Based English (Levels 2-8), and Math (Levels 2-8).'
  }
};

export default translations;
