import React, { Component } from 'react';
import { Provider } from 'mobx-react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import RouterScrollToTop from '../components/RouterScrollToTop';

import '../css/App.less';
import '../css/print/App.less';

import accessManager from '../managers/AccessManager';
import accommodationsManager from '../managers/AccommodationsManager';
import adminClassroomManager from '../managers/AdminClassroomManager';
import adminUsersManager from '../managers/AdminUsersManager';
import appManager from '../managers/AppManager';
import assessmentManager from '../managers/AssessmentManager';
import assignmentManager from '../managers/AssignmentManager';
import classroomManager from '../managers/ClassroomManager';
import classroomRosterManager from '../managers/ClassroomRosterManager';
import contentItemManager from '../managers/ContentItemManager';
import contentManager from '../managers/ContentManager';
import courseDeliveryManager from '../managers/CourseDeliveryManager';
import courseManager from '../managers/CourseManager';
import curriculumMapManager from '../managers/CurriculumMapManager';
import dialogManager from '../managers/DialogManager';
import docReaderManager from '../managers/DocReaderManager';
import dynamicSatelliteManager from '../managers/DynamicSatelliteManager';
import engagementManager from '../managers/EngagementManager';
import exportManager from '../managers/ExportManager';
import filteredHeaderTableManager from '../managers/FilteredHeaderTableManager';
import gradebookManager from '../managers/GradebookManager';
import groupsManager from '../managers/GroupsManager';
import itemBankManager from '../managers/ItemBankManager';
import learnosityAuthorManager from '../managers/LearnosityAuthorManager';
import learnosityDataManager from '../managers/LearnosityDataManager';
import learnosityItemsManager from '../managers/LearnosityItemsManager';
import libraryManager from '../managers/LibraryManager';
import navigationManager from '../managers/NavigationManager';
import notificationManager from '../managers/NotificationManager';
import productManager from '../managers/ProductManager';
import reportContextManager from '../managers/reports/ReportContextManager';
import reportCourseManager from '../managers/reports/ReportCourseManager';
import reportIdentityManager from '../managers/reports/ReportIdentityManager';
import reportOverrideManager from '../managers/reports/ReportOverrideManager';
import reportScoreManager from '../managers/reports/ReportScoreManager';
import reportStandardsClassroomDetailStudentsManager from '../managers/reports/ReportStandardsClassroomDetailStudentsManager';
import reportStandardsManager from '../managers/reports/ReportStandardsManager';
import reportTableManager from '../managers/reports/ReportTableManager';
import reportTypeManager from '../managers/reports/ReportTypeManager';
import reportUsageManager from '../managers/reports/ReportUsageManager';
import resourceBankShopManager from '../managers/ResourceBankShopManager';
import schoolManager from '../managers/SchoolManager';
import studentContentCardManager from '../managers/StudentContentCardManager';
import studentProgressManager from '../managers/StudentProgressManager';
import tagContentManager from '../managers/TagContentManager';
import teacherProductViewManager from '../managers/TeacherProductViewManager';
import thirdPartyManager from '../managers/ThirdPartyManager';
import topNavManager from '../managers/TopNavManager';
import userManager from '../managers/UserManager';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.CertificateView = SatCoreComponent('CertificateView');
    this.ClassCourses = SatCoreComponent('ClassCourses');
    this.DemolinkLauncherView = SatCoreComponent('DemolinkLauncherView');
    this.ForgotPassword = SatCoreComponent('ForgotPassword');
    this.LTIContent = SatCoreComponent('LTIContent');
    this.LTIPlayerLauncher = SatCoreComponent('LTIPlayerLauncher');
    this.LTIView = SatCoreComponent('LTIView');
    this.LearnosityPrintPreview = SatCoreComponent('LearnosityPrintPreview');
    this.LessonPortal = SatCoreComponent('LessonPortal');
    this.Login = SatCoreComponent('Login');
    this.LoginOneRoster = SatCoreComponent('LoginOneRoster');
    this.MainView = SatCoreComponent('MainView');
    this.NewPassword = SatCoreComponent('NewPassword');
    this.PublisherCourse = SatCoreComponent('PublisherCourse');
    this.Register = SatCoreComponent('Register');
    this.RegisterEcommerce = SatCoreComponent('RegisterEcommerce');
    this.RegisterInvite = SatCoreComponent('RegisterInvite');
    this.StudentAssignmentLauncher = SatCoreComponent('StudentAssignmentLauncher');
    this.Verification = SatCoreComponent('Verification');
  }

  render() {
    return (
      <BrowserRouter>
        <RouterScrollToTop />
        <Provider
          accessManager={accessManager}
          accommodationsManager={accommodationsManager}
          adminClassroomManager={adminClassroomManager}
          adminUsersManager={adminUsersManager}
          appManager={appManager}
          assessmentManager={assessmentManager}
          assignmentManager={assignmentManager}
          classroomManager={classroomManager}
          classroomRosterManager={classroomRosterManager}
          contentItemManager={contentItemManager}
          contentManager={contentManager}
          courseDeliveryManager={courseDeliveryManager}
          courseManager={courseManager}
          curriculumMapManager={curriculumMapManager}
          dialogManager={dialogManager}
          docReaderManager={docReaderManager}
          dynamicSatelliteManager={dynamicSatelliteManager}
          engagementManager={engagementManager}
          exportManager={exportManager}
          filteredHeaderTableManager={filteredHeaderTableManager}
          gradebookManager={gradebookManager}
          groupsManager={groupsManager}
          itemBankManager={itemBankManager}
          learnosityAuthorManager={learnosityAuthorManager}
          learnosityDataManager={learnosityDataManager}
          learnosityItemsManager={learnosityItemsManager}
          libraryManager={libraryManager}
          navigationManager={navigationManager}
          notificationManager={notificationManager}
          productManager={productManager}
          reportContextManager={reportContextManager}
          reportCourseManager={reportCourseManager}
          reportIdentityManager={reportIdentityManager}
          reportOverrideManager={reportOverrideManager}
          reportScoreManager={reportScoreManager}
          reportStandardsClassroomDetailStudentsManager={reportStandardsClassroomDetailStudentsManager}
          reportStandardsManager={reportStandardsManager}
          reportTableManager={reportTableManager}
          reportTypeManager={reportTypeManager}
          reportUsageManager={reportUsageManager}
          resourceBankShopManager={resourceBankShopManager}
          schoolManager={schoolManager}
          studentContentCardManager={studentContentCardManager}
          studentProgressManager={studentProgressManager}
          tagContentManager={tagContentManager}
          teacherProductViewManager={teacherProductViewManager}
          thirdPartyManager={thirdPartyManager}
          topNavManager={topNavManager}
          userManager={userManager}>
          <Switch>
            <Route component={this.Login} path='/signin' />
            <Route component={this.LoginOneRoster} path='/loginOneRoster' />
            <Route component={this.Register} path='/register' />
            <Route component={this.RegisterEcommerce} path='/activate' />
            <Route component={this.DemolinkLauncherView} path='/demolink/:demolinkId' />
            <Route component={this.RegisterInvite} path='/invitation/:id' />
            <Route component={this.ForgotPassword} path='/forgot-password' />
            <Route component={this.Verification} path='/verification' />
            <Route component={this.PublisherCourse} path='/publisherCourse' />
            <Route component={this.NewPassword} path='/changePassword' />
            <Route component={this.LTIPlayerLauncher} path='/ltiPlayer' />
            <Route component={this.StudentAssignmentLauncher} path='/launch' />
            <Route component={this.LessonPortal} path='/lesson-portal' />
            <Route component={this.LearnosityPrintPreview} path='/lrnPrint' />
            <Route component={this.LTIContent} path='/lti-courses' />
            <Route component={this.CertificateView} path='/certificate' />
            <Route component={this.MainView} path='/' />
          </Switch>
        </Provider>
      </BrowserRouter>
    );
  }
}

SatCoreRegister('App', App);
